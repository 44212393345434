@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');

body {
  margin: 0;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/public/assets/fondo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
