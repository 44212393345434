// CLASES DEL PDF VIEW

.pdf-view-custom {
  display: inline-grid;
  align-items: center;
  justify-content: center;
}

.pdf-view-custom-div {
  background: #2c2f32;
  font-size: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  .pdf-download-catalog {
    margin-left: 8px;
  }

  button {
    background: transparent;
    border: transparent;
    font-size: 140%;

    &.disabled {
      background: transparent;
      border: transparent;
    }
  }
}

.pdf-view-custom-page {
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
}

.pdf-view-custom-button-minus {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-view-custom-page-number {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 400;
}

.pdf-viewer-background {
  background: #484c4f;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}
